<template>
    <v-select
      v-model="selectedFilters"
      label="Search Filters"
      :items="filters"
      item-text="name"
      item-value="key"
      background-color="input"
      :menu-props="{ offsetY: true, maxHeight: '600' }"
      multiple
      outlined
      hide-details
      small-chips
      dense>
      <template v-slot:selection="{ attrs, item }">
        <template v-if="item.key === 'party_types' && (params.party_types.length > 0)">
          <v-chip
            v-for="party_type in selectedPartyTypes"
            :key="party_type.id"
            small>
            {{ party_type.name }}
            <v-icon
              small
              right
              @click="removePartyType(party_type.constant)">
              $delete
            </v-icon>
          </v-chip>
        </template>
        <v-chip
          v-else
          v-bind="attrs"
          small>
          {{ getFilterText(item) }}
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ item, attrs }">
        <v-list-item>
          <v-list-item-content>
            <v-autocomplete
              v-if="item.key === 'party_types'"
              v-model="params.party_types"
              :label="item.name"
              :items="party_types"
              item-text="name"
              item-value="constant"
              hide-details
              multiple
              small-chips
              deletable-chips
              @change="handleChangedArray($event, item.key)"
              clearable
              outlined
              dense>
            </v-autocomplete>
            <v-text-field
              v-else
              v-model="params[`${item.key}`]"
              :label="item.name"
              hide-details
              background-color="input"
              :prefix="item.prefix || null"
              :type="item.type"
              :autofocus="(selectedFilters.length === 0) && item.key === 'party_name'"
              dense
              clearable
              @keyup.stop="attrs.inputValue ? null : handleInputValue(item)"
              @click:clear="removeFilter(item.key)"
              outlined>
            </v-text-field>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </template>
  <script>
  
  // mixins
  import { userAccess } from '@/mixins/user-access'
  import { utils } from '@/mixins/utils'
  import { displayAlert } from '@/mixins/alert'

  // third-party
  import { debounce } from 'lodash'

  export default {
    data () {
      return {
        selectedFilters: [],
        selectedVendors: [],
        params: {
          party_name: '',
          party_email: '',
          party_types: []
        },
        excluded_party_types: {
          awg: ['OFFICE', 'PERISHABLE_GROUP', 'STORE_GROUP', 'TPR_GROUP', 'WAREHOUSE']
        }
      }
    },
    name: 'SearchFilters',
    mixins: [userAccess, utils, displayAlert],
    props: {
      value: Object
    },
    watch: {
      activeFilters: {
        handler: debounce(function (newValue) {
          if (newValue) {
            this.updateSearchFilters()
          }
        }, 400),
        deep: true
      }
    },
    created () {
      if (sessionStorage.party_search_filters) {
        const stored = JSON.parse(sessionStorage.party_search_filters)
        this.params = { ...this.params, ...stored }
        const activeKeys = Object.keys(stored).filter(key => Boolean(stored[key]))
        if (activeKeys.length > 0) this.selectedFilters.push(...activeKeys)
      }
    },
    computed: {
      filters () {
        return [
          { name: 'Party Name', key: 'party_name', type: 'text' },
          { name: 'Party Email', key: 'party_email', type: 'text' },
          { name: 'Wholesaler Store Number', key: 'WHOLESALER_STORE_NUMBER', type: 'number' },
          { name: 'Vendor ID', key: 'VENDOR_ID', type: 'text' },
          { name: 'Party Types', key: 'party_types' }
        ]
      },
      activeFilters () {
        return Object.keys(this.params).filter(this.isActive)
      },
      party_types () {
        let types = this.$store.getters.party_types
        if (this.tenant === 'awg') {
          types = types.filter(p => !this.excluded_party_types[this.tenant].includes(p?.constant))
        }
        return this.sortByKey(types, 'name')
      },
      selectedPartyTypes () {
        return this.party_types.filter(p => this.params.party_types.includes(p?.constant))
      }
    },
    methods: {
      isActive (key) {
        if (this.isSelected(key) && Boolean(this.params[key])) {
          const value = this.params[key]
          return Array.isArray(value) ? (value.length > 0) : true
        }
        return false
      },
      getFilterText (item) {
        return `${item.text || item.name}: "${this.params[item.key]}"`
      },
      removeFilter (key, clearValue = false) {
        const index = this.selectedFilters.indexOf(key)
        if (index > -1) {
          this.selectedFilters.splice(index, 1)
          if (clearValue) this.params[key] = null
        }
      },
      removePartyType (constant) {
        const index = this.params.party_types.indexOf(constant)
        if (index > -1) {
          this.params.party_types.splice(index, 1)
          if (this.params.party_types.length === 0) {
            this.removeFilter('party_types')
          }
        }
      },
      handleInputValue: debounce(function (item) {
        if (Boolean(this.params[item.key]) && !this.isSelected(item.key)) {
          this.selectedFilters.push(item.key)
        }
      }, 300),
      handleChangedArray (newValue, key) {
        if ((newValue.length > 0) && !this.isSelected(key)) {
          this.selectedFilters.push(key)
        } else if ((newValue.length === 0) && this.isSelected(key)) {
          this.removeFilter(key)
        }
      },
      isSelected (key) {
        return this.selectedFilters.includes(key)
      },
      updateSearchFilters () {
        const filtersObj = this.activeFilters.reduce((obj, key) => {
          obj[key] = this.params[key]
          return obj
        }, {})
        this.$emit('input', filtersObj)
        if (this.activeFilters.length > 0) {
          sessionStorage.setItem('party_search_filters', JSON.stringify(filtersObj))
        } else {
          sessionStorage.removeItem('party_search_filters')
        }
      }      
    }
  }
  </script>