export const partySearch = {
    data () {
      return {
        displayNameConfigs: [
          { type: 'STORE', attr: 'WHOLESALER_STORE_NUMBER' },
          { type: 'VENDOR', attr: 'VENDOR_ID' }
        ]
      }
    },
    methods: {
      formatParty (party) {
        let display_name = party.party_name || party.name
        const partyType = party.party_type_constant || party.party_type.constant
        const config = this.displayNameConfigs.find(c => c.type === partyType)
        
      
        if (config && party.attributes) {
          let attrVal = party.attributes[config.attr] || ''
          if (attrVal && partyType === 'STORE') {
            attrVal = `#${attrVal}`
          }
          display_name = `${attrVal} ${display_name}`
        }
      
        party.name = display_name
        return party
      },
      formatPartyType (str) {
        str = str.toLowerCase().replaceAll('_', ' ')
        const parts = str.split(' ').map(part => part.charAt(0).toUpperCase() + part.slice(1))
        return parts.join(' ')
      }
    }
  }